import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import "./TermsTab.css";

const General = (props) => {
  return (
    <Fragment>
      <div className="tc main-pad py-md-5 py-4">
        <h3> INTRODUCTION </h3>
        <ul>
          <li>
            These terms and conditions (“Terms and Conditions”) govern your use
            of products or services from phoenixmarketcity.com provided by “The
            Phoenix Mills Ltd” (hereinafter referred to as “Phoenix
            Marketcity”), its subsidiaries, affiliates and related entities
            (“Island Star Mall Developers Pvt Ltd”, “we”, “us”, and “our”) and
            offered for Users. Additional or separate terms may apply to your
            use of other Company websites, services, or apps, or participation
            in any promotion. To the extent that the provisions of any
            additional terms conflict with these Terms and Conditions, the
            provisions of the additional terms will prevail at the discretion of
            the Company. By downloading, installing or using the Site, or
            accessing our website, you (“User”, “you” or “your”) agree to the
            Terms and Conditions below.
          </li>
          <li>
            This document is an electronic record in terms of the Information
            Technology Act, 2000 (“IT Act”) and rules thereunder and is
            published in accordance with the provisions thereunder which
            mandates for publishing of the privacy policy and other terms of use
            for access or usage of the Website. This electronic record is
            generated by a computer system and does not require any physical or
            digital signatures.
          </li>

          <li>
            By using this website you are deemed to have read and agreed to the
            following terms and conditions: Subject to these Terms and
            Conditions, Island Star Mall Developers Pvt Ltd grants Users a
            limited, revocable, non-exclusive, non-transferable personal right
            to access and make use of the Site and services solely for the
            benefit of the User and no other commercial purpose. If you do not
            comply with these Terms and Conditions, Island Star Mall Developers
            Pvt Ltd reserves the right to revoke your license or limit your
            access to the Site.
          </li>
          <li>
            Island Star Mall Developers Pvt Ltd reserves the right to remove any
            information or material on the Site without prior warning or
            intimation.
          </li>
          <li>
            The Site and related services are not aimed at nor intended for
            children under the age of 16. You must be 16 years of age or over to
            use the Site. If you are under 18 years of age (but over 16), you
            must obtain the consent of a parent or guardian before accessing or
            using the Site or creating a Registration.
          </li>
          <li>
            These terms, along with the Privacy Policy discussed below,
            (together, these Terms and the Privacy Policy are called the
            “Terms”), govern your access to and use of the Site and any related
            interactive and informational services made available to consumers
            or you (“Services”) on any media platform and constitute a binding
            legal agreement between you, as a user of the Program, and the
            Island Star Mall Developers Pvt Ltd (the “Agreement”). Your access
            to and use of the Services requires your compliance with the Terms
            and by downloading and/or accessing the Site and/or accessing and/or
            using any portion of the Services, you agree to be bound by this
            Agreement.
          </li>
        </ul>
        <br />
        <br />
        <h3> REGISTRATION</h3>
        <ul>
          <li>
            You may also opt to register with us by using the Sign In/Register
            tab which will be authenticated once you register yourself the
            Island Star Mall Developers Pvt Ltd will send you the verification
            email on your email id which have provided to the Island Star Mall
            Developers Pvt Ltd. To create an Account, you must be 18 years of
            age and older at the time of registration, to participate in any of
            the activities/ contests. If you are younger than 18 years of age,
            then you need to ensure your parents’ consent to your using the
            Services. You will be solely responsible for any activities or
            actions taken under your Account, whether or not authorized by you.
            Please notify us immediately of any unauthorized use of your
            Account. We are not liable for any loss or damage from your failure
            to comply with these requirements. You agree that the information
            you provide us will be true, accurate, current and complete. By
            creating an Account you consent to the use of: (i) electronic means
            to complete the Terms and to provide you with any notices given
            pursuant to the Terms; and (ii) electronic records to store
            information related to the Terms or your use of the Services.
          </li>
          <li>
            By creating an account, you represent, warrant and covenant that you
            provide us with accurate, truthful, and complete registration
            information (including, but not limited to your name (“username”),
            e-mail address, age and gender etc.) and to keep your registration
            information accurate and up-to-date. Failure to do so shall
            constitute a breach of these Terms of Use, which may result in
            immediate termination of your Account. You shall not :
            <br />
            provide any false personal information to Island Star Mall
            Developers Pvt Ltd (including a false username) or create any
            account for anyone other than yourself without such person’s
            permission;
            <br />
            use a username that is the name of another person with the intent to
            impersonate that person.
            <br />
            use a username or Company account that is subject to any rights of a
            person other than you without appropriate authorization; or
            <br />
            use a username that is a name that is otherwise offensive, vulgar or
            obscene or otherwise unlawful.
          </li>
        </ul>
        <p>
          In order to facilitate services through the Website, Island Star Mall
          Developers Pvt Ltd may from time to time enter into various
          arrangements with third party vendors, who shall be solely responsible
          to display for sale its catalogue, API of goods and services and combo
          offers of Merchants of Island Star Mall Developers Pvt Ltd Mall on the
          Website. You shall be bound by the terms and conditions of such third
          party vendors as well for purchase of goods, if any and services
          through the Website. You further represent and warrant to abide by the
          Terms and Conditions of such third party as well.
          <br />
          <br />
          You understand that by using the Service, you may encounter data,
          information, applications, materials and other content from third
          parties, including other users (collectively, “Third Party
          Materials”), that may be offensive, indecent, or objectionable.
          Nevertheless, you agree to use the Service at your sole risk and that
          Island Star Mall Developers Pvt Ltd shall not have any liability
          towards the same i.e. any Service Content that user may found to be
          offensive, indecent, or that is inaccurate, incomplete, untimely,
          invalid, illegal, indecent, of poor quality or otherwise
          objectionable. You use the Service, and rely upon any Service Content
          accessible through the Service, at your sole risk.
          <br />
          <br />
          The Website makes no representation that the Service, any third party
          services, and Service Content are appropriate or available for use in
          any particular location. To the extent you choose to access such
          services or materials, you do so at your own initiative and are
          responsible for compliance with any and all applicable laws.
          <br />
          <br />
          Island Star Mall Developers Pvt Ltd shall not be held responsible for
          any dispute/disagreement that may arise while doing any sort of
          transaction through third party vendor and/or third party payment
          gateway and/or any such platform by using the services via said
          website.
          <br />
          <br />
          For any Complaints or concerns the user can contact Island Star Mall
          Developers Pvt Ltd, but the discretion and the decision to qualify the
          complaint or concerns for further action remains with the Island Star
          Mall Developers Pvt Ltd.
        </p>
      </div>
    </Fragment>
  );
};

export default General;
